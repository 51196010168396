import React from 'react';

import Layout from '../components/Layout';
import Header from '../components/Header';
import HomeHeader from '../components/Header/HomeHeader';
import Footer from '../components/Footer';
import { useMainPageData } from '../queries/useMainPageData';
import Sections from '../components/Sections';

function Index() {
    const { seo, blocks } = useMainPageData();

    return (
        <Layout seoMeta={seo}>
            <Header bgColor="sand">
                <HomeHeader />
            </Header>
            <Sections
                enabledSections={blocks}
                options={{
                    actionBlockMiddle: { whiteStyle: true },
                }}
            />
            <Footer />
        </Layout>
    );
}

export default Index;
